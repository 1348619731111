import React, { useEffect, useState } from 'react';
import TableComponent from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { map, get, reduce } from 'lodash';
import './style.scss';

function Table({
  getData, deleteData, headers, data, defaultOrderByField, defaultOrderDirection, title, actions,
}) {
  const [orderByField, setOrderByField] = useState(defaultOrderByField === '' ? get(headers, '[0].id', '') : defaultOrderByField);
  const [orderDirection, setOrderDirection] = useState(defaultOrderDirection);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    getData({
      orderByField, orderDirection,
    });
  }, [orderByField, orderDirection]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOrderChange = (item) => {
    if (orderByField === item) {
      setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setOrderDirection('desc');
      setOrderByField(item);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const downloadCSV = ({ name }) => {
    if (data.length) {
      const config = mkConfig({ filename: name, useKeysAsHeaders: true });
      const csv = generateCsv(config)(data);
      download(config)(csv);
    }
  };

  const headerElements = map(headers, (item) => (
    <TableCell key={`header-${item.id}`}>
      <TableSortLabel
        active={orderByField === item.id}
        direction={orderDirection}
        onClick={() => handleOrderChange(item.id)}
      >
        {item.label}
      </TableSortLabel>
    </TableCell>
  ));

  const dataElements = reduce(data, (memo, item, itemIndex) => {
    if (itemIndex >= page * rowsPerPage && memo.length < rowsPerPage) {
      const cells = map(headers, (headerItem) => (
        <TableCell key={`cell-${item.id}-${headerItem.id}`}>
          {get(item, headerItem.id)}
        </TableCell>
      ));
      memo.push(
        <TableRow key={`row-${item.id}`}>
          {cells}
          <TableCell>
            <button type="button" className="button negative" onClick={() => deleteData({ id: item.id, orderByField, orderDirection })}>
              Delete
            </button>
          </TableCell>
        </TableRow>,
      );
    }
    return memo;
  }, []);

  const actionElements = map(actions, (action, index) => (
    <button key={`action-${index}`} type="button" disabled={data.length === 0} className={`button ${action.className || ''}`} onClick={() => action.function({})}>
      {action.label}
    </button>
  ));

  return (
    <div className="component-table">
      <div className="table-header">
        <h2>{title}</h2>
        <div className="buttons">
          <button type="button" disabled={data.length === 0} className="button" onClick={() => downloadCSV({ name: title.toLowerCase(), data })}>
            Export to CSV
          </button>
          {actionElements}
        </div>
      </div>
      <TableContainer component={Paper}>
        <TableComponent size="small">
          <TableHead>
            <TableRow>
              {headerElements}
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataElements}
          </TableBody>
        </TableComponent>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

Table.propTypes = {
  getData: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    function: PropTypes.func.isRequired,
    className: PropTypes.string,
  })),
  title: PropTypes.string,
  defaultOrderByField: PropTypes.string,
  defaultOrderDirection: PropTypes.string,
};

Table.defaultProps = {
  headers: [],
  data: [],
  actions: [],
  defaultOrderByField: '',
  title: '',
  defaultOrderDirection: 'desc',
};

export default Table;
