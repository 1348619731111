import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import Grid from '@mui/material/Unstable_Grid2';
import { Hero } from '../../components';

function NotFoundPage() {
  useEffect(() => {
    document.title = 'Leamington Medical Centre - Not Found';
    firebase.analytics().logEvent('page_view', {
      firebase_screen: 'Not Found',
    });
  }, []);

  return (
    <div className="page-not-found">
      <Hero maskRight>
        <Grid className="grid-container" container spacing={2}>
          <Grid mdOffset={6} md={6}>
            <h1 className="center">
              Not found
            </h1>
            <p className="center paragraph-1">
              Page Not Found
            </p>
          </Grid>
        </Grid>
      </Hero>
    </div>
  );
}

export default NotFoundPage;
