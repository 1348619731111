import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Header, Menu, Footer } from '../../components';
import { mapDispatchToProps, mapStateToProps } from './selector';
import './styles.scss';

function Page({ getConfig }) {
  useEffect(() => {
    getConfig();
  }, []);

  return (
    <div className="page">
      <Header />
      <Menu active="home" />
      <div className="page-content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

Page.propTypes = {
  getConfig: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
