import React from 'react';
import PropTypes from 'prop-types';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import './style.scss';

function Contact({ primaryColor, secondaryColor, color }) {
  return (
    <div className="component-contact">
      <LocalPhoneIcon sx={{ color: primaryColor, fontSize: 40, marginRight: '.5rem' }} />
      <div className="column">
        <a style={{ color }} href="tel:+6478275959">+64 07 827 5959</a>
        <a style={{ color: secondaryColor }} href="mailto:admin@leamingtonmc.co.nz" className="secondary-color">admin@leamingtonmc.co.nz</a>
      </div>
    </div>
  );
}

Contact.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  color: PropTypes.string,
};

Contact.defaultProps = {
  primaryColor: '#4766BA',
  secondaryColor: '#A5A5A5',
  color: '#404040',
};

export default Contact;
