import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Address from '../address';
import Contact from '../contact';
import WorkingHours from '../working-hours';
import logo from '../../assets/images/logo.png';
import './style.scss';

function Header() {
  return (
    <header className="component-header">
      <div className="header">
        <div className="content">
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid xs={12} lg={3} xl={6} className="logo-cell">
              <a href="/" target="_self">
                <img src={logo} className="logo" alt="Leamington Medical" />
              </a>
            </Grid>
            <Grid
              xs={12}
              lg={3}
              xl={2}
              display={{
                xs: 'none', md: 'block', lg: 'block', xl: 'block',
              }}
            >
              <WorkingHours />
            </Grid>
            <Grid
              xs={12}
              lg={3}
              xl={2}
              display={{
                xs: 'none', md: 'block', lg: 'block', xl: 'block',
              }}
            >
              <Contact />
            </Grid>
            <Grid
              xs={12}
              lg={3}
              xl={2}
              display={{
                xs: 'none', md: 'block', lg: 'block', xl: 'block',
              }}
            >
              <Address />
            </Grid>
          </Grid>
        </div>
      </div>
    </header>
  );
}

export default Header;
