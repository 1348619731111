import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import Grid from '@mui/material/Unstable_Grid2';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { EnrolForm, WaitlistForm, Hero } from '../../components';
import { mapDispatchToProps, mapStateToProps } from './selector';
import './style.scss';

function EnrolPage({ enrollmentOpen }) {
  useEffect(() => {
    document.title = 'Leamington Medical Centre - Enrol';
    firebase.analytics().logEvent('page_view', {
      firebase_screen: 'Enrol',
    });
  }, []);

  return (
    <div className="">
      {enrollmentOpen && (
      <Hero animate className="hero-6">
        <Grid className="grid-container" container spacing={2}>
          <Grid md={6} xs={12}>
            <h1>
              <span className="highlight-color">Join</span>
              <span>
                our Leamington
                <br />
                Medical Centre
                <br />
              </span>
              <span className="highlight-color">Community</span>
            </h1>
            <p className="paragraph-1">
              Leamington Medical Centre is open for new patients
              and would love to welcome you and your family in our
              community and Whānau!
            </p>
          </Grid>
          <Grid md={6} />
          <Grid xs={12} sm={6} md={3}>
            <div className="bottom-box">
              <div className="icon-circle">
                <ScreenShareIcon sx={{ color: '#fff', fontSize: 50 }} />
              </div>
              <div>
                <h3>Enrolment</h3>
                <p>
                  Use our online enrolment form
                  to make the process easier for
                  you and your family
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={3} className="options">
            <h3>Or</h3>
            <a href="mailto:admin@leamingtonmc.co.nz" className="button">
              Contact Us
            </a>
          </Grid>
        </Grid>
      </Hero>
      )}
      {enrollmentOpen ? (
        <Hero animate className="hero-5">
          <Grid container spacing={2}>
            <Grid xs={12}>
              <h1 className="center">
                <span>How do I</span>
                <span className="highlight-color">Enrol</span>
                <span>with you</span>
              </h1>
              <p className="center paragraph-1">
                We are excited to have you enrol with
                us and join a community of patients that are
                supported by a group of world class health professionals.
                <br />
                Enroling is easy, you can choose from 3 different options:
              </p>
            </Grid>
            <Grid xs={12} md={4} className="options">
              <h3 className="center">OPTION 1: Enrol Online </h3>
              <a href="/enroll#enrollment-form" className="button">
                Enrol Online Today
              </a>
              <p>
                Complete an online enrolment form today,
                upload proof of identification, and submit!
                Once arrive at the clinic for the first time we
                will have you sign it with one of our staff.
              </p>
            </Grid>
            <Grid xs={12} md={4} className="options">
              <h3 className="center">OPTION 2: Download Form</h3>
              <button type="button" className="button">
                Download enrolment form
              </button>
              <p>
                Download and print your enrolment form.
                Once completed, and signed, either bring
                it by the clinic or email us the completed form
                to
                {' '}
                <a href="mailto:admin@leamingtonmc.co.nz" className="highlight-color">admin@leamingtonmc.co.nz</a>
                .
                <br />
                <br />
                Be sure to include your photo ID, proof of
                eligibility to enrol (NZ passport or NZ birth
                certificate or valid visa) and proof of address.
                We’ll take care of the rest!
              </p>
            </Grid>
            <Grid xs={12} md={4} className="options">
              <h3 className="center">OPTION 3: Office Visit</h3>
              <a href="https://maps.app.goo.gl/LrJeSxFBv7CqsVhe7" className="button" target="_blank" rel="noreferrer">
                Check Our Address
              </a>
              <p>
                Feel free to stop by the office and pick up
                an enrolment form. Complete it while there
                or later. Once the form has been completed,
                bring it back to the office to be finalised.
                <br />
                <br />
                When you come back to the office, be sure
                to bring your photo ID, proof of
                eligibility to enrol (NZ passport or NZ birth
                certificate or valid visa) and proof of address.
                We’ll take care of the rest!
              </p>
            </Grid>
          </Grid>
        </Hero>
      ) : (
        <Hero animate className="hero-5">
          <Grid container spacing={2}>
            <Grid xs={12}>
              <h1 className="center">
                <span>How do I</span>
                <span className="highlight-color">join the Waitlist</span>
                <span>with you?</span>
              </h1>
              <p className="center paragraph-1">
                We are excited that you would like to join our waitlist
                and we look forward to you joining a community of patients
                that are supported by a group of world class health professionals.
                Please complete the waitlist form below
              </p>
            </Grid>
          </Grid>
        </Hero>
      )}
      {enrollmentOpen ? <EnrolForm /> : <WaitlistForm />}
    </div>
  );
}

EnrolPage.propTypes = {
  enrollmentOpen: PropTypes.bool,
};

EnrolPage.defaultProps = {
  enrollmentOpen: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrolPage);
