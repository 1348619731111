import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import FormHelperText from '@mui/material/FormHelperText';
import {
  map, get, indexOf, reduce, each, set, omit,
} from 'lodash';
import './style.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './selector';

const FORM_FIELDS = [
  {
    section: 'Name',
    subtext: 'required',
    fields: [
      {
        type: 'autocomplete',
        options: ['Mr.', 'Mrs.'],
        label: 'Title',
        value: 'title',
        mdSize: 1,
      },
      {
        label: 'Given Name',
        value: 'givenName',
        mdSize: 3,
        required: true,
      },
      {
        label: 'Other Given Name(s)',
        value: 'otherGivenNames',
        mdSize: 2,
      },
      {
        label: 'Family Name',
        value: 'familyName',
        mdSize: 3,
        required: true,
      },
      {
        label: 'Other Names',
        value: 'otherNames',
        mdSize: 2,
        subtext: 'eg. Maiden Name',
      },
    ],
  },
  {
    section: 'Contact Details',
    subtext: 'required',
    fields: [
      {
        label: 'Mobile Phone',
        value: 'mobilePhone',
        required: true,
        mdSize: 3,
      },
      {
        label: 'Home Phone',
        value: 'homePhone',
        mdSize: 3,
      },
      {
        label: 'Email Address',
        value: 'emailAddress',
        required: true,
        mdSize: 4,
      },
      {
        type: 'spacer',
        mdSize: 1,
      },
    ],
  },
  {
    section: 'Additional Details',
    fields: [
      {
        label: 'Additional Details',
        value: 'additionalDetails',
        mdSize: 11,
      },
    ],
  },
];

function WaitlistForm({ sendWaitlistForm }) {
  const [form, setFormField] = useState({
    gender: null,
    ethnicity: null,
    communityServicesCard: null,
    highUserHealthCard: null,
  });
  const [validation, setValidation] = useState({});
  const [submitted, setSubmitted] = useState(false);

  function isFieldVisible(field) {
    if (field.displayCondition) {
      if (indexOf(field.displayCondition.value, get(form, field.displayCondition.field)) === -1) {
        return false;
      }
    }
    return true;
  }

  function validateField(field, value) {
    const isVisible = isFieldVisible(field);
    if (field.required && isVisible && (value === '' || value === undefined || value === null)) {
      setValidation({
        ...validation,
        [field.value]: `${field.label} is required`,
      });
    } else if (get(validation, field.value)) {
      setValidation({
        ...omit(validation, [field.value]),
      });
    }
  }

  function validateForm() {
    const newValidation = reduce(FORM_FIELDS, (memo, section) => {
      each(section.fields, (field) => {
        const value = get(form, field.value, null);
        const isVisible = isFieldVisible(field);
        if (field.required && isVisible && (value === '' || value === undefined || value === null)) {
          set(memo, field.value, `${field.label} is required`);
        }
      });
      return memo;
    }, {});
    setValidation(newValidation);
    return Object.keys(newValidation).length === 0;
  }

  function saveForm() {
    const isValid = validateForm();
    if (isValid) {
      sendWaitlistForm({
        name: `${form.title || ''} ${form.familyName || ''}, ${form.givenName || ''} ${form.otherGivenNames || ''}`.trim(), otherNames: form.otherNames || '', email: form.emailAddress || '', mobilePhone: form.mobilePhone || '', homePhone: form.homePhone || '', additionalDetails: form.additionalDetails || '',
      });
      setSubmitted(true);
    }
  }

  function onChange(field, value) {
    setFormField({
      ...form,
      [field]: value,
    });
  }

  const sections = map(FORM_FIELDS, (section, index) => {
    const fields = map(section.fields, (field, fieldIndex) => {
      let fieldElement;
      const value = get(form, field.value);
      const error = get(validation, field.value, '');
      const hasError = error !== '';
      const isVisible = isFieldVisible(field);
      if (!isVisible) {
        return null;
      }
      if (field.type === 'autocomplete') {
        fieldElement = (
          <Autocomplete
            value={value}
            required={field.required || false}
            options={field.options}
            onChange={(e, newValue) => onChange(field.value, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                required={field.required || false}
                label={field.label}
                error={hasError}
                helperText={error}
                onBlur={() => validateField(field, value)}
              />
            )}
          />
        );
      } else if (field.type === 'date') {
        fieldElement = (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              className="form-field"
              label={field.label}
              value={value}
              required={field.required || false}
              onChange={(date) => onChange(field.value, date.format('DD/MM/YYYY'))}
              disableFuture={field.disableFuture || false}
              format="DD/MM/YYYY"
              error={hasError}
              helperText={error}
              onBlur={() => validateField(field, value)}
            />
          </LocalizationProvider>
        );
      } else if (field.type === 'spacer') {
        return (
          <Grid key={`field-${index}-${fieldIndex}`} xs={12} md={field.mdSize} />
        );
      } else {
        fieldElement = (
          <TextField
            className="form-field"
            label={field.label}
            value={value}
            required={field.required || false}
            onChange={(e) => onChange(field.value, e.target.value)}
            error={hasError}
            helperText={error}
            onBlur={(e) => validateField(field, e.target.value)}
          />
        );
      }

      return (
        <Grid key={`field-${index}-${fieldIndex}`} xs={12} md={field.mdSize}>
          {fieldElement}
          {field.subtext && (<FormHelperText>{field.subtext}</FormHelperText>)}
        </Grid>
      );
    });

    return [
      <Grid key={`section-${index}`} xs={12} md={1}>
        <span className="label">{section.section}</span>
        {section.subtext && (<span className="subext">{section.subtext}</span>)}
      </Grid>,
      fields,
    ];
  });

  return (
    <div className="component-waitlist-form">
      <div className="content">
        <h2 id="waitlist-form">
          <span>Online</span>
          <span className="highlight-color">Waitlist Form</span>
        </h2>
        <p>
          Below is an online waitlist form, once completed the
          clinic will be notified
        </p>
        <form autoComplete="off">
          <div className="form-section">
            Waitlist Details
          </div>
          <Grid className="grid-container" container spacing={2}>
            {sections}
          </Grid>
        </form>
      </div>
      <div className="submit">
        <button type="button" className="button" disabled={submitted} onClick={() => !submitted && saveForm()}>
          {submitted ? 'Submitted' : 'Join Waitlist'}
        </button>
        <p>
          When a place at the clinic becomes free
          we will contact you
        </p>
      </div>
    </div>
  );
}

WaitlistForm.propTypes = {
  sendWaitlistForm: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitlistForm);
