import { getConfig } from '../../actions/config/actions';

export function mapStateToProps() {
  return {
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getConfig: () => dispatch(getConfig()),
  };
}
