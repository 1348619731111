import React from 'react';
import './style.scss';

function News() {
  return (
    <div className="component-news">
      <h2>
        <span>Latest</span>
        <span className="highlight-color">News</span>
      </h2>
      <p>
        Read our latest news and updates from the clinic.
        Feel free to reach out if you have any questions!
      </p>
      <iframe title="Facebook News Feed" className="content iframe" src="https://widget.tagembed.com/126888?view" />
    </div>
  );
}

export default News;
