import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import Grid from '@mui/material/Unstable_Grid2';
import servicesPdf from '../../assets/documents/services.pdf';
import { Hero, Services } from '../../components';
import './style.scss';

function ServicesPage() {
  useEffect(() => {
    document.title = 'Leamington Medical Centre - Services & Fees';
    firebase.analytics().logEvent('page_view', {
      firebase_screen: 'Services & Fees',
    });
  }, []);

  return (
    <div className="page-services-fees">
      <Hero className="hero-7" animate maskRight>
        <Grid container className="grid-container" spacing={2}>
          <Grid xs={12} mdOffset={8} md={4}>
            <h1>
              <span className="highlight-color">Offering</span>
              <span>a range of </span>
              <span className="highlight-color">services</span>
              <span>to our community</span>
            </h1>
            <p>
              Leamington Family Medical Centre offers
              Several different services, procedures, and
              consults. Learn more about these offerings
              below and the associated cost.
            </p>
            <div className="options">
              <a href={servicesPdf} target="_blank" rel="noopener noreferrer" className="button">
                Our Services (PDF)
              </a>
            </div>
          </Grid>
        </Grid>
      </Hero>
      <div className="section">
        <div className="content">
          <h2>
            <span>Supporting</span>
            <span className="highlight-color">Medical Services</span>
            <span>For the whole family</span>
          </h2>
          <p>
            Leamington Family Medical Centre has focused on drawing talented,
            accredited medical practitioners together. So we can
            provide a one stop destination for your healthcare needs.
            At our medical centre you will find a range of services and facilities
            available, with an emphasis on access, affordability and convenience.
            Our staff all work collaboratively with the Doctors
            and other Health Practitioners, to deliver the best possible
            Family Practice experience for each patient and the local community.
            <br />
            <br />
            Below are a list of our services you can expect from Leamington Family Medical Centre.
          </p>
        </div>
      </div>
      <Services />
    </div>
  );
}

export default ServicesPage;
