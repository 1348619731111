import { sendSubscription } from '../../actions/subscriptions/actions';

export function mapStateToProps(state) {
  return {
    isLoading: state.subscriptions.isLoading,
    isSubscribed: state.subscriptions.isSubscribed,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    sendSubscription: (params) => dispatch(sendSubscription(params)),
  };
}
