import { createReducer } from '../../store';
import {
  SEND_SUBSCRIPTION_REQUEST,
  SEND_SUBSCRIPTION_RESPONSE,
  SEND_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_RESPONSE,
  GET_SUBSCRIPTIONS_FAILURE,
} from './actions-definitions';

const initialState = {
  isLoading: false,
  subscriptions: [],
  isSubscribed: false,
};

export default createReducer(initialState, {
  [SEND_SUBSCRIPTION_REQUEST](state) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [SEND_SUBSCRIPTION_RESPONSE](state) {
    return {
      ...state,
      isSubscribed: true,
      isLoading: false,
    };
  },
  [SEND_SUBSCRIPTION_FAILURE](state) {
    return {
      ...state,
      isLoading: false,
    };
  },
  [GET_SUBSCRIPTIONS_REQUEST](state) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [GET_SUBSCRIPTIONS_RESPONSE](state, action) {
    return {
      ...state,
      subscriptions: action.subscriptions,
      isLoading: false,
    };
  },
  [GET_SUBSCRIPTIONS_FAILURE](state) {
    return {
      ...state,
      isLoading: false,
    };
  },
});
