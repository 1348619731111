import {
  collection, getFirestore, addDoc, getDocs, writeBatch,
  serverTimestamp, deleteDoc, doc, query, where, orderBy,
} from 'firebase/firestore';
import { map } from 'lodash';
import moment from 'moment';
import {
  SEND_SUBSCRIPTION_REQUEST,
  SEND_SUBSCRIPTION_RESPONSE,
  SEND_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_RESPONSE,
  GET_SUBSCRIPTIONS_FAILURE,
} from './actions-definitions';

function formatDate(date = moment()) {
  return moment(date.toDate()).format('MMM Do YYYY, h:mm:ss a');
}

function sendSubscription({ email }) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_SUBSCRIPTION_REQUEST });
      const db = getFirestore();
      const subscriptionsRef = collection(db, 'subscriptions');
      const q = query(subscriptionsRef, where('email', '==', email));

      getDocs(q)
        .then((snapshot) => {
          if (snapshot.docs.length) {
            dispatch({ type: SEND_SUBSCRIPTION_RESPONSE });
          } else {
            addDoc(subscriptionsRef, {
              email,
              status: 'REQUESTED',
              createdAt: serverTimestamp(),
              lastChangedAt: serverTimestamp(),
              createdBy: 'SYSTEM',
              lastChangedBy: 'SYSTEM',
            })
              .then(() => {
                dispatch({ type: SEND_SUBSCRIPTION_RESPONSE });
              });
          }
        });
    } catch (err) {
      dispatch({ type: SEND_SUBSCRIPTION_FAILURE, err });
    }
  };
}

async function getSubscriptionsData({ orderByField, orderDirection }) {
  try {
    const db = getFirestore();
    const subscriptionsRef = collection(db, 'subscriptions');
    const q = query(subscriptionsRef, orderBy(orderByField, orderDirection));
    const snapshot = await getDocs(q);
    return map(snapshot.docs, (subscription) => {
      const data = subscription.data();
      return {
        id: subscription.id,
        ...data,
        createdAt: formatDate(data.createdAt),
        lastChangedAt: formatDate(data.lastChangedAt),
      };
    });
  } catch (err) {
    return [{}];
  }
}

function deleteSubscription({
  id, orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBSCRIPTIONS_REQUEST });
      const db = getFirestore();
      await deleteDoc(doc(db, 'subscriptions', id));
      const subscriptions = await getSubscriptionsData({ orderByField, orderDirection });
      dispatch({ type: GET_SUBSCRIPTIONS_RESPONSE, subscriptions });
    } catch (err) {
      dispatch({ type: GET_SUBSCRIPTIONS_FAILURE, err });
    }
  };
}

function deleteAllEnlistedSubscriptions({
  orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBSCRIPTIONS_REQUEST });
      const db = getFirestore();
      const batch = writeBatch(db);
      const subscriptionsRef = collection(db, 'subscriptions');
      const q = query(subscriptionsRef, where('status', '==', 'ENLISTED'));
      const snapshot = await getDocs(q);
      snapshot.docs.forEach((subscription) => {
        const ref = doc(db, 'subscriptions', subscription.id);
        batch.delete(ref);
      });
      await batch.commit();
      const subscriptions = await getSubscriptionsData({ orderByField, orderDirection });
      dispatch({ type: GET_SUBSCRIPTIONS_RESPONSE, subscriptions });
    } catch (err) {
      dispatch({ type: GET_SUBSCRIPTIONS_FAILURE, err });
    }
  };
}

function enlistAllSubscriptions({
  orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBSCRIPTIONS_REQUEST });
      const db = getFirestore();
      const batch = writeBatch(db);
      const subscriptionsRef = collection(db, 'subscriptions');
      const q = query(subscriptionsRef, where('status', '!=', 'ENLISTED'));
      const snapshot = await getDocs(q);
      snapshot.docs.forEach((subscription) => {
        const ref = doc(db, 'subscriptions', subscription.id);
        batch.update(ref, {
          status: 'ENLISTED',
          lastChangedAt: serverTimestamp(),
        });
      });
      await batch.commit();
      const subscriptions = await getSubscriptionsData({ orderByField, orderDirection });
      dispatch({ type: GET_SUBSCRIPTIONS_RESPONSE, subscriptions });
    } catch (err) {
      dispatch({ type: GET_SUBSCRIPTIONS_FAILURE, err });
    }
  };
}

function getSubscriptions({
  orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBSCRIPTIONS_REQUEST });
      const subscriptions = await getSubscriptionsData({ orderByField, orderDirection });
      dispatch({ type: GET_SUBSCRIPTIONS_RESPONSE, subscriptions });
    } catch (err) {
      dispatch({ type: GET_SUBSCRIPTIONS_FAILURE, err });
    }
  };
}

export {
  sendSubscription,
  getSubscriptions,
  deleteSubscription,
  enlistAllSubscriptions,
  deleteAllEnlistedSubscriptions,
  formatDate,
};
