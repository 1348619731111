import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from '../index';
import { mapDispatchToProps, mapStateToProps } from './selector';
import './style.scss';

function Admin({
  getSubscriptions, deleteSubscription, deleteAllEnlistedSubscriptions,
  enlistAllSubscriptions, subscriptions, getPrints, deletePrint, prints,
  deleteAllRegisteredPrints, registerAllPrints,
  getWaitlists, deleteWaitlist, deleteAllWaitlists, waitlists,
}) {
  return (
    <div className="component-admin">
      <div className="content">
        <div className="section">
          <Table
            title="Subscriptions"
            getData={getSubscriptions}
            deleteData={deleteSubscription}
            actions={[
              {
                label: 'Mark all as Enlisted',
                function: enlistAllSubscriptions,
              }, {
                label: 'Delete all Enlisted',
                function: deleteAllEnlistedSubscriptions,
                className: 'negative',
              },
            ]}
            headers={[{ id: 'email', label: 'Email' },
              { id: 'status', label: 'Status' },
              { id: 'createdAt', label: 'Created At' },
              { id: 'createdBy', label: 'Created By' },
              { id: 'lastChangedAt', label: 'Last Changed At' },
              { id: 'lastChangedBy', label: 'Last Changed By' }]}
            data={subscriptions}
            defaultOrderByField="createdAt"
            defaultOrderDirection="desc"
          />
        </div>
        <div className="section">
          <Table
            title="Waitlist"
            getData={getWaitlists}
            deleteData={deleteWaitlist}
            actions={[
              {
                label: 'Delete all',
                function: deleteAllWaitlists,
                className: 'negative',
              },
            ]}
            headers={[{ id: 'name', label: 'Name' },
              { id: 'email', label: 'Email' },
              { id: 'mobilePhone', label: 'Mobile Phone' },
              { id: 'homePhone', label: 'Home Phone' },
              { id: 'status', label: 'Status' },
              { id: 'createdAt', label: 'Created At' },
              { id: 'createdBy', label: 'Created By' },
              { id: 'lastChangedAt', label: 'Last Changed At' },
              { id: 'lastChangedBy', label: 'Last Changed By' }]}
            data={waitlists}
            defaultOrderByField="createdAt"
            defaultOrderDirection="desc"
          />
        </div>
        <div className="section">
          <div className="section">
            <Table
              title="Printed Forms"
              getData={getPrints}
              deleteData={deletePrint}
              actions={[
                {
                  label: 'Mark all as Registered',
                  function: registerAllPrints,
                }, {
                  label: 'Delete all Registered',
                  function: deleteAllRegisteredPrints,
                  className: 'negative',
                },
              ]}
              headers={[{ id: 'name', label: 'Name' },
                { id: 'email', label: 'Email' },
                { id: 'mobilePhone', label: 'Mobile Phone' },
                { id: 'homePhone', label: 'Home Phone' },
                { id: 'status', label: 'Status' },
                { id: 'createdAt', label: 'Created At' },
                { id: 'createdBy', label: 'Created By' },
                { id: 'lastChangedAt', label: 'Last Changed At' },
                { id: 'lastChangedBy', label: 'Last Changed By' }]}
              data={prints}
              defaultOrderByField="createdAt"
              defaultOrderDirection="desc"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Admin.propTypes = {
  getSubscriptions: PropTypes.func.isRequired,
  deleteSubscription: PropTypes.func.isRequired,
  deleteAllEnlistedSubscriptions: PropTypes.func.isRequired,
  enlistAllSubscriptions: PropTypes.func.isRequired,
  getPrints: PropTypes.func.isRequired,
  deletePrint: PropTypes.func.isRequired,
  registerAllPrints: PropTypes.func.isRequired,
  deleteAllRegisteredPrints: PropTypes.func.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  })),
  prints: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    mobilePhone: PropTypes.string.isRequired,
    homePhone: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastChangedAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    lastChangedBy: PropTypes.string.isRequired,
  })),
};

Admin.defaultProps = {
  subscriptions: [],
  prints: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
