export function mapStateToProps(state) {
  return {
    pricing: state.config.pricing,
  };
}

export function mapDispatchToProps() {
  return {
  };
}
