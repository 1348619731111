import React from 'react';
import PropTypes from 'prop-types';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import './style.scss';

function Address({ primaryColor, color }) {
  return (
    <a href="https://maps.app.goo.gl/LrJeSxFBv7CqsVhe7" className="component-address" target="_blank" rel="noreferrer">
      <EditLocationIcon sx={{ color: primaryColor, fontSize: 40, marginRight: '.5rem' }} />
      <address className="column">
        <span style={{ color }}>127 Shakespeare Street</span>
        <span style={{ color }}>Leamington, Cambridge</span>
      </address>
    </a>
  );
}

Address.propTypes = {
  primaryColor: PropTypes.string,
  color: PropTypes.string,
};

Address.defaultProps = {
  primaryColor: '#4766BA',
  color: '#404040',
};

export default Address;
