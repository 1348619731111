import { createReducer } from '../../store';
import {
  GET_FORM_WAITLIST_REQUEST,
  GET_FORM_WAITLIST_RESPONSE,
  GET_FORM_WAITLIST_FAILURE,
} from './actions-definitions';

const initialState = {
  isLoading: false,
  waitlists: [],
};

export default createReducer(initialState, {
  [GET_FORM_WAITLIST_REQUEST](state) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [GET_FORM_WAITLIST_RESPONSE](state, action) {
    return {
      ...state,
      waitlists: action.waitlists,
      isLoading: false,
    };
  },
  [GET_FORM_WAITLIST_FAILURE](state) {
    return {
      ...state,
      isLoading: false,
    };
  },
});
