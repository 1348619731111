import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';
import {
  GET_CONFIG_REQUEST,
  GET_CONFIG_RESPONSE,
  GET_CONFIG_FAILURE,
} from './actions-definitions';

function getConfig() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CONFIG_REQUEST });
      const remoteConfig = firebase.remoteConfig();
      remoteConfig.settings.minimumFetchIntervalMillis = 100;
      remoteConfig.fetchAndActivate()
        .then(() => {
          dispatch({
            type: GET_CONFIG_RESPONSE,
            enrollmentOpen: remoteConfig.getValue('enrollment_open').asBoolean(),
            pricing: {
              standardAppointmentPricing: JSON.parse(remoteConfig.getValue('standard_appointment_pricing').asString()),
              administrativeFeesPricing: JSON.parse(remoteConfig.getValue('administrative_fees_pricing').asString()),
              nurseProcedurePricing: JSON.parse(remoteConfig.getValue('nurse_procedures_pricing').asString()),
              prescriptionsPricing: JSON.parse(remoteConfig.getValue('prescriptions_pricing').asString()),
              otherPricing: JSON.parse(remoteConfig.getValue('other_pricing').asString()),
            },
          });
        });
    } catch (err) {
      dispatch({ type: GET_CONFIG_FAILURE, err });
    }
  };
}

export {
  getConfig,
};
