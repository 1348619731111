import React from 'react';
import { Provider } from 'react-redux';
import {
  RouterProvider, Route, createBrowserRouter, createRoutesFromElements,
} from 'react-router-dom';
import { initializeApp } from './firebase';
import { configureStore } from './store';
import {
  HomePage, AboutUsPage, AdminPage, EnrolPage, NotFoundPage, Page, ServicesPage, FAQPage,
} from './pages';
import './assets/fonts/avenir-book.ttf';
import './assets/fonts/avenir-heavy.ttf';
import './styles.scss';

export default function App() {
  const store = configureStore();
  initializeApp();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Page />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/enrol" element={<EnrolPage />} />
        <Route path="/our-services" element={<ServicesPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>,
    ),
  );

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}
