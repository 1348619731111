import {
  getSubscriptions,
  deleteSubscription,
  deleteAllEnlistedSubscriptions,
  enlistAllSubscriptions,
} from '../../actions/subscriptions/actions';
import {
  getPrints, deletePrint, deleteAllRegisteredPrints, registerAllPrints,
} from '../../actions/enrollment/actions';
import {
  getWaitlists, deleteWaitlist, deleteAllWaitlists,
} from '../../actions/waitlist/actions';

export function mapStateToProps(state) {
  return {
    isSubscriptionsLoading: state.subscriptions.isLoading,
    subscriptions: state.subscriptions.subscriptions,
    isPrintsLoading: state.enrollment.isLoading,
    prints: state.enrollment.prints,
    waitlists: state.waitlist.waitlists,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getSubscriptions: (params) => dispatch(getSubscriptions(params)),
    deleteSubscription: (params) => dispatch(deleteSubscription(params)),
    deleteAllEnlistedSubscriptions: (params) => dispatch(deleteAllEnlistedSubscriptions(params)),
    enlistAllSubscriptions: (params) => dispatch(enlistAllSubscriptions(params)),
    getPrints: (params) => dispatch(getPrints(params)),
    deletePrint: (params) => dispatch(deletePrint(params)),
    deleteAllRegisteredPrints: (params) => dispatch(deleteAllRegisteredPrints(params)),
    registerAllPrints: (params) => dispatch(registerAllPrints(params)),
    getWaitlists: (params) => dispatch(getWaitlists(params)),
    deleteWaitlist: (params) => dispatch(deleteWaitlist(params)),
    deleteAllWaitlists: (params) => dispatch(deleteAllWaitlists(params)),
  };
}
