import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import Grid from '@mui/material/Unstable_Grid2';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {
  Hero, Buttons, Values, News, Map,
} from '../../components';

function HomePage() {
  useEffect(() => {
    document.title = 'Leamington Medical Centre - Home';
    firebase.analytics().logEvent('page_view', {
      firebase_screen: 'Home',
    });
  }, []);

  return (
    <div className="page-home">
      <Hero animate maskRight>
        <Grid className="grid-container" container spacing={2}>
          <Grid xs={12} md={6} mdOffset={6}>
            <h1>
              <span>Putting</span>
              <span className="highlight-color">Family</span>
              <span>and</span>
              <span className="highlight-color">Community</span>
              <span>first</span>
            </h1>
            <p className="paragraph-1">
              Providing a family friendly general practice for even the youngest patients.
              <br />
              Our aim is to provide you and your family with the best available healthcare.
            </p>
          </Grid>
          <Grid xs={12} sm={6} md={3} mdOffset={6}>
            <div className="bottom-box">
              <div className="icon-circle">
                <SmartphoneIcon sx={{ color: '#fff', fontSize: 50 }} />
              </div>
              <div>
                <h3>Flexibility</h3>
                <p>
                  Supporting booked appointments for consultations,
                  face-to-face, Phone consults
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <div className="bottom-box">
              <div className="icon-circle">
                <AccessAlarmIcon sx={{ color: '#fff', fontSize: 50 }} />
              </div>
              <div>
                <h3>Onsite Facilities</h3>
                <p>Onsite Pathlab and co-location with Leamington Unichem Pharmacy </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Hero>
      <Buttons />
      <Values />
      <Hero animate className="hero-2">
        <Grid className="grid-container" container spacing={2}>
          <Grid xs={12} md={4}>
            <h1>
              <span>Our</span>
              <span className="highlight-color">Promise</span>
            </h1>
            <p className="paragraph-1">
              Providing a family friendly general practice for even the youngest patients.
              Our aim is to provide you and your family with the best available healthcare.
            </p>
            <div className="bottom-box">
              <Link to="/about-us" className="button">
                Learn More
              </Link>
            </div>
          </Grid>
        </Grid>
      </Hero>
      <News />
      <Map />
      <Hero className="hero-5" containerClassName="testimonials-container">
        <Grid className="grid-container" container spacing={2}>
          <Grid container xs={12} md={8} className="left-testimonial">
            <Grid xs={12}>
              <h1 className="center">
                <span>
                  <span>Patient</span>
                  <span className="highlight-color">Testimonials</span>
                </span>
                <div className="spacer" />
              </h1>
              <p className="center paragraph-1">
                It’s always the world of mouth that’s the best advice,
                here are some testimonials from our customers…
              </p>
              <div className="testimonials">
                <p className="paragraph-1">
                  Leamington Medical have always given me such great, personal care.
                  Especially when my children have had asthma issues and needed urgent care.
                  Highly recommend.
                </p>
                <p className="paragraph-1">
                  Very helpful staff, the waiting time was not as long
                  as I am used to and the doctor was very good.
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4} className="right-testimonial">
            <Grid xs={12}>
              <h1 className="center">
                <span>Kid&#39;s Thoughts</span>
                <div className="spacer" />
              </h1>
              <p className="center paragraph-1">
                What do parents of our youngest patients say?
              </p>
            </Grid>
            <div className="testimonials">
              <p className="paragraph-1">
                Leamington Medical have always given me such great,
                personal care. Especially when my children had asthma issues and
                needed urgent care. Highly recommend.
              </p>
            </div>
          </Grid>
        </Grid>
      </Hero>
    </div>
  );
}

export default HomePage;
