import { sendFormPrint } from '../../actions/enrollment/actions';

export function mapStateToProps() {
  return {
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    sendFormPrint: (params) => dispatch(sendFormPrint(params)),
  };
}
