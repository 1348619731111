import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class Hero extends Component {
  constructor() {
    super();
    this.state = {
      transform: 1,
      height: 0,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const { animate } = this.props;
    if (animate) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    const { animate } = this.props;
    if (animate) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll(event) {
    let { height } = this.state;
    if (!height) {
      height = event.srcElement.body.offsetHeight;
    }
    this.setState({
      transform: 1 - (window.scrollY / (height - window.innerHeight)),
      height,
    });
  }

  render() {
    const {
      animate, maskRight, className, children, containerClassName,
    } = this.props;
    const { transform } = this.state;
    let style = {};
    if (animate) {
      style = { backgroundPositionY: `${-30 * transform + 60}%` };
    }

    return (
      <div className={`component-hero ${containerClassName}`}>
        <div className={`content-mask ${maskRight && 'mask-right'}`} />
        <div className={`hero ${className}`} style={style} />
        <div className="content">
          {children}
        </div>
      </div>
    );
  }
}

Hero.propTypes = {
  animate: PropTypes.bool,
  maskRight: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.node,
};

Hero.defaultProps = {
  animate: false,
  maskRight: false,
  className: '',
  containerClassName: '',
  children: null,
};

export default Hero;
