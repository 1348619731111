import React, { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import firebase from 'firebase/compat/app';
import { Hero } from '../../components';
import FAQAccordion from '../../components/faq-accordion';
import './styles.scss';

function FAQPage() {
  useEffect(() => {
    document.title = 'Leamington Medical Centre - FAQ';
    firebase.analytics().logEvent('page_view', {
      firebase_screen: 'FAQ',
    });
  }, []);

  const faqData = [
    {
      question: 'What is your policy on Child protection?',
      answer: 'Our policy is attached here.',
    },
    {
      question: 'How do I find my results?',
      answer: 'If you have manage my health you can see all your test results once they have been reviewed. It is our policy to contact you regarding abnormal results.',
    },
    {
      question: 'What if I am sick and need care after hours?',
      answer: 'You can be seen at the emergency room at Waikato hospital. There are also many urgent cares you can seek care from including Anglesea Urgent care, Mahoe Med, Victoria clinic, Tui urgent care. You can always call Health line for free advise 0800 611 116\nAnglesea urgent care Open 24/7 07 858 0800 3 Thackeray street Hamilton\nMahoe med Open Hours Vary 07 872 0923 670/4 Cambridge Rd Te Awamutu\nVictoria Clinic Open 7 days 8am-8pm 07 834 0333 173 Anglesea St Hamilton\nTui Medical Centre Urgent Care 24/7 0800 175 175 26 Bryant Road Te Rapa Hamilton',
    },
    {
      question: 'How do I order a repeat prescription?',
      answer: 'You can make a request through your manage my health portal, or call the clinic and leave a message on the nurse line.',
    },
    {
      question: 'Do you have a walk in clinic?',
      answer: 'Not at this time, but we have reserved appointments times for same day appointments. These are triaged by a nurse so that we can allocate these spots for those who need an urgent appointment.',
    },
    {
      question: 'Do you have weekend hours?',
      answer: 'We don’t currently offer weekend hours.',
    },
    {
      question: 'What if I am not happy with the care I recieve?',
      answer: 'We welcome all feedback, including ideas for improvement. Please email manager@leamingtonmc.co.nz',
    },
    {
      question: 'What if I want to enrol in your practice?',
      answer: 'We aren’t always accepting new enrolments. But we can join the waitlist here and we will notify you when we have an opening.',
    },
    {
      question: 'Why does it take so long to get an appointment?',
      answer: 'There is a huge GP shortage in New Zealand. This means longer wait times. We want all patients to feel cared for and to ensure we can look after you when you are sick we dedicate same day appointments slots to be booked by a nurse.',
    },
  ];

  return (
    <div className="page-faq">
      <Hero className="hero-3" maskRight>
        <Grid className="grid-container" container spacing={2}>
          <Grid xs={12} md={6} mdOffset={6}>
            <h1>
              <span>Frequently Asked</span>
              <span className="highlight-color">Questions</span>
            </h1>
            <p className="paragraph-1">
              Have a question? We're here to help. Browse through our frequently asked questions below.
            </p>
          </Grid>
        </Grid>
      </Hero>
      <div className="faq-section">
        <Grid className="grid-container" container spacing={2}>
          <Grid xs={12} md={8} mdOffset={2}>
            <FAQAccordion data={faqData} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default FAQPage;
