import React from 'react';
import { map } from 'lodash';
import Grid from '@mui/material/Unstable_Grid2';
import { Link, useLocation } from 'react-router-dom';
import './style.scss';

const MENU = [
  {
    id: 'home',
    label: 'Home',
    url: '/',
  },
  {
    id: 'aboutUs',
    label: 'About Us',
    url: '/about-us',
  },
  {
    id: 'ourServices',
    label: 'Services & Fees',
    url: '/our-services',
  },
  {
    id: 'healthResources',
    label: 'Health Resources',
    url: 'https://www.mayoclinic.org/symptom-checker/select-symptom/itt-20009075',
    target: '_blank',
  },
  {
    id: 'enrol',
    label: 'Enrolment',
    url: '/enrol',
  },
  {
    id: 'requestAnAppointment',
    label: 'Manage My Health',
    url: 'https://app.managemyhealth.co.nz/authentication/login',
    target: '_blank',
  },
  {
    id: 'faq',
    label: 'FAQ',
    url: '/faq',
  },
];

function Menu() {
  const location = useLocation();
  const items = map(MENU, (item) => (
    <div key={`menu-${item.id}`} className="menu-item-container">
      <div className={`menu-item ${item.url === location.pathname ? 'active' : ''}`}>
        <Link to={item.url} target={item.target || '_self'}>
          {item.label}
        </Link>
      </div>
    </div>
  ));

  return (
    <div className="component-menu">
      <nav className="content">
        {items}
      </nav>
    </div>
  );
}

export default Menu;
