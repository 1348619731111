import React from 'react';
import Address from '../address';
import Contact from '../contact';
import WorkingHours from '../working-hours';
import './style.scss';

function Map() {
  return (
    <div className="component-map">
      <div className="content">
        <div className="info">
          <h2>
            Working Hours
          </h2>
          <div>
            <div className="row working-hours">
              <span className="bold">Mon - Fri</span>
              <span>8:00 AM - 5:00 PM</span>
            </div>
            <div className="row working-hours">
              <span className="bold">Sat - Sun</span>
              <span>Closed</span>
            </div>
          </div>
          <h2>
            Contact Us
          </h2>
          <Address primaryColor="#fff" color="#fff" />
          <Contact primaryColor="#fff" secondaryColor="#fff" color="#fff" />
          <WorkingHours primaryColor="#fff" secondaryColor="#fff" color="#fff" />
        </div>
      </div>
      <iframe
        title="Map"
        style={{ border: 0, height: 500, width: '100%' }}
        loading="lazy"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAbHibNQfak3pGm6o89jZjjkFHjcpKLv9Q&q=Leamington+Medical+Centre"
      />
    </div>
  );
}

export default Map;
