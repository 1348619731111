import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import {
  getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut,
} from 'firebase/auth';
import TextField from '@mui/material/TextField';
import { Admin } from '../../components';
import './style.scss';

function AdminPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    document.title = 'Leamington Medical Centre - Admin';
    firebase.analytics().logEvent('page_view', {
      firebase_screen: 'Admin',
    });

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });
  }, []);

  const login = () => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(() => {

      });
  };

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setIsLoggedIn(false);
      })
      .catch(() => {

      });
  };

  return (
    <div className="page-admin">
      {isLoggedIn ? (
        <div>
          <Admin />
          <div className="form">
            <div className="content">
              <button type="button" onClick={() => logout()} className="button">Logout</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="form">
          <div className="content">
            <form>
              <TextField
                className="field"
                label="Email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                className="field"
                type="password"
                label="Password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="button" onClick={() => login()} className="button">Login</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminPage;
