import { combineReducers } from 'redux';
import subscriptions from './actions/subscriptions/reducer';
import enrollment from './actions/enrollment/reducer';
import waitlist from './actions/waitlist/reducer';
import config from './actions/config/reducer';

export default combineReducers({
  subscriptions,
  enrollment,
  waitlist,
  config,
});
