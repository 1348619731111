import {
  collection, getFirestore, addDoc, getDocs,
  serverTimestamp, deleteDoc, doc, query, orderBy, writeBatch, where,
} from 'firebase/firestore';
import { map } from 'lodash';
import { formatDate } from '../subscriptions/actions';
import {
  SEND_FORM_PRINT_REQUEST,
  SEND_FORM_PRINT_RESPONSE,
  SEND_FORM_PRINT_FAILURE,
  GET_FORM_PRINTS_REQUEST,
  GET_FORM_PRINTS_RESPONSE,
  GET_FORM_PRINTS_FAILURE,
} from './actions-definitions';

function sendFormPrint({
  name, email, mobilePhone, homePhone,
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_FORM_PRINT_REQUEST });
      const db = getFirestore();
      const printsRef = collection(db, 'prints');

      addDoc(printsRef, {
        name,
        email,
        mobilePhone,
        homePhone,
        status: 'PRINTED',
        createdAt: serverTimestamp(),
        lastChangedAt: serverTimestamp(),
        createdBy: 'SYSTEM',
        lastChangedBy: 'SYSTEM',
      })
        .then(() => {
          dispatch({ type: SEND_FORM_PRINT_RESPONSE });
        });
    } catch (err) {
      dispatch({ type: SEND_FORM_PRINT_FAILURE, err });
    }
  };
}

async function getPrintsData({ orderByField, orderDirection }) {
  try {
    const db = getFirestore();
    const printsRef = collection(db, 'prints');
    const q = query(printsRef, orderBy(orderByField, orderDirection));

    const snapshot = await getDocs(q);
    return map(snapshot.docs, (print) => {
      const data = print.data();
      return {
        id: print.id,
        ...data,
        createdAt: formatDate(data.createdAt),
        lastChangedAt: formatDate(data.lastChangedAt),
      };
    });
  } catch (err) {
    return [];
  }
}

function deletePrint({
  id, orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORM_PRINTS_REQUEST });
      const db = getFirestore();
      await deleteDoc(doc(db, 'prints', id));
      const prints = await getPrintsData({ orderByField, orderDirection });
      dispatch({ type: GET_FORM_PRINTS_RESPONSE, prints });
    } catch (err) {
      dispatch({ type: GET_FORM_PRINTS_FAILURE, err });
    }
  };
}

function getPrints({
  orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORM_PRINTS_REQUEST });
      const prints = await getPrintsData({ orderByField, orderDirection });
      dispatch({ type: GET_FORM_PRINTS_RESPONSE, prints });
    } catch (err) {
      dispatch({ type: GET_FORM_PRINTS_FAILURE, err });
    }
  };
}

function deleteAllRegisteredPrints({
  orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORM_PRINTS_REQUEST });
      const db = getFirestore();
      const batch = writeBatch(db);
      const printsRef = collection(db, 'prints');
      const q = query(printsRef, where('status', '==', 'REGISTERED'));
      const snapshot = await getDocs(q);
      snapshot.docs.forEach((print) => {
        const ref = doc(db, 'prints', print.id);
        batch.delete(ref);
      });
      await batch.commit();
      const prints = await getPrintsData({ orderByField, orderDirection });
      dispatch({ type: GET_FORM_PRINTS_RESPONSE, prints });
    } catch (err) {
      dispatch({ type: GET_FORM_PRINTS_FAILURE, err });
    }
  };
}

function registerAllPrints({
  orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORM_PRINTS_REQUEST });
      const db = getFirestore();
      const batch = writeBatch(db);
      const printsRef = collection(db, 'prints');
      const q = query(printsRef, where('status', '!=', 'REGISTERED'));
      const snapshot = await getDocs(q);
      snapshot.docs.forEach((print) => {
        const ref = doc(db, 'prints', print.id);
        batch.update(ref, {
          status: 'REGISTERED',
          lastChangedAt: serverTimestamp(),
        });
      });
      await batch.commit();
      const prints = await getPrintsData({ orderByField, orderDirection });
      dispatch({ type: GET_FORM_PRINTS_RESPONSE, prints });
    } catch (err) {
      dispatch({ type: GET_FORM_PRINTS_FAILURE, err });
    }
  };
}

export {
  sendFormPrint,
  getPrints,
  deletePrint,
  deleteAllRegisteredPrints,
  registerAllPrints,
};
