import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

export function initializeApp() {
  const firebaseConfig = {
    apiKey: 'AIzaSyAbHibNQfak3pGm6o89jZjjkFHjcpKLv9Q',
    authDomain: 'leamington-medical.firebaseapp.com',
    projectId: 'leamington-medical',
    storageBucket: 'leamington-medical.appspot.com',
    messagingSenderId: '427881204362',
    appId: '1:427881204362:web:ddf601a22169fafdb37b73',
    measurementId: 'G-X703T0XG60',
  };

  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}
