import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import GroupIcon from '@mui/icons-material/Group';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import CommentIcon from '@mui/icons-material/Comment';
import StoreIcon from '@mui/icons-material/Store';
import './style.scss';

function Values() {
  return (
    <div className="component-values">
      <div className="content">
        <h2>
          <span>Our Clinic</span>
          <span className="highlight-color">Values</span>
        </h2>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4}>
            <div className="value">
              <FavoriteIcon sx={{
                color: '#4766BA', fontSize: 50, marginRight: '1rem', marginTop: '-1rem',
              }}
              />
              <div>
                <h3>Compassionate Care</h3>
                <p>
                  Providing empathetic and understanding treatment to every patient,
                  ensuring they feel valued and cared for throughout their visit
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <div className="value">
              <PersonOutlineOutlinedIcon sx={{
                color: '#4766BA', fontSize: 50, marginRight: '1rem', marginTop: '-1rem',
              }}
              />
              <div>
                <h3>Clear Communication</h3>
                <p>
                  Offering transparent and easy-to-understand explanations about diagnoses,
                  treatment options, and medical procedures to
                  empower patients in their healthcare decisions
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <div className="value">
              <EventSeatIcon sx={{
                color: '#4766BA', fontSize: 50, marginRight: '1rem', marginTop: '-1rem',
              }}
              />
              <div>
                <h3>Prioritising Urgent Needs</h3>
                <p>
                  We are committed to addressing urgent medical needs promptly and efficiently,
                  ensuring patients receive timely care when they need it most
                </p>
              </div>
            </div>
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <div className="value">
              <GroupIcon sx={{
                color: '#4766BA', fontSize: 50, marginRight: '1rem', marginTop: '-1rem',
              }}
              />
              <div>
                <h3>Personalised Attention</h3>
                <p>
                  Providing thorough follow-up care and checking in with patients to
                  monitor progress, address concerns, and adjust treatment plans as needed
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <div className="value">
              <CommentIcon sx={{
                color: '#4766BA', fontSize: 50, marginRight: '1rem', marginTop: '-1rem',
              }}
              />
              <div>
                <h3>Accessibility</h3>
                <p>
                  Ensuring that patients can easily reach the clinic, schedule appointments,
                  and access medical advice, including providing
                  support for those with special needs or language barriers
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <div className="value">
              <StoreIcon sx={{
                color: '#4766BA', fontSize: 50, marginRight: '1rem', marginTop: '-1rem',
              }}
              />
              <div>
                <h3>Follow-Up Support</h3>
                <p>
                  Providing thorough follow-up care and checking in with
                  patients to monitor progress, address concerns,
                  and adjust treatment plans as needed
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Values;
