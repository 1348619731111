import React, { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import firebase from 'firebase/compat/app';
import { Hero, Values } from '../../components';
import Team from '../../components/team';

function AboutUsPage() {
  useEffect(() => {
    document.title = 'Leamington Medical Centre - About Us';
    firebase.analytics().logEvent('page_view', {
      firebase_screen: 'About Us',
    });
  }, []);

  return (
    <div className="page-about-us">
      <Hero className="hero-3" maskRight>
        <Grid className="grid-container" container spacing={2}>
          <Grid xs={12} md={6} mdOffset={6}>
            <h1>
              <span>Learn more</span>
              <span className="highlight-color">About Us</span>
              <span>and Our Community</span>
            </h1>
            <p className="paragraph-1">
              We look forward to meeting you in person,
              in the mean-time learn more our about our clinic and
              our team of amazing health care professionals!
            </p>
          </Grid>
        </Grid>
      </Hero>
      <Values />
      <Hero className="hero-4" maskRight>
        <Grid className="grid-container" container spacing={2}>
          <Grid xs={12} md={6} mdOffset={6}>
            <h1 className="center">
              <span>About Our</span>
              <span className="highlight-color">Clinic</span>
            </h1>
          </Grid>
          <Grid xs={12} md={3} mdOffset={6}>
            <div className="hero-title center">
              <h2>30+</h2>
            </div>
            <div className="bottom-box center">
              <div>
                <h3>Years with You</h3>
                <p>
                  Supporting booked appointments for consultations,
                  face-to-face, Phone consults
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={3}>
            <div className="hero-title center">
              <h2>7</h2>
            </div>
            <div className="bottom-box center">
              <div>
                <h3>Health Care Providers</h3>
                <p>
                  Supporting booked appointments for consultations,
                  face-to-face, Phone consults
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={3} mdOffset={6}>
            <div className="hero-title center">
              <h2>17</h2>
            </div>
            <div className="bottom-box center">
              <div>
                <h3>Nurses & Staff</h3>
                <p>
                  Supporting booked appointments for consultations,
                  face-to-face, Phone consults
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={3}>
            <div className="hero-title center">
              <h2>3</h2>
            </div>
            <div className="bottom-box center">
              <div>
                <h3>Certifications</h3>
                <p>
                  Supporting booked appointments for consultations,
                  face-to-face, Phone consults
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Hero>
      <Team />
    </div>
  );
}

export default AboutUsPage;
