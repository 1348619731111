import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import GroupIcon from '@mui/icons-material/Group';
import DevicesIcon from '@mui/icons-material/Devices';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { Link } from 'react-router-dom';
import './style.scss';

function Buttons() {
  return (
    <div className="component-buttons">
      <div className="content">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={3}>
            <Link to="/enrol" className="button">
              <GroupIcon sx={{ color: '#fff', fontSize: 45, marginBottom: '.5rem' }} />
              <h3>Enrolment</h3>
            </Link>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <Link to="https://www.mayoclinic.org/symptom-checker/select-symptom/itt-20009075" target="_blank" className="button">
              <DevicesIcon sx={{ color: '#fff', fontSize: 45, marginBottom: '.5rem' }} />
              <h3>Health Resources</h3>
            </Link>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <Link to="/our-services" className="button">
              <TouchAppIcon sx={{ color: '#fff', fontSize: 45, marginBottom: '.5rem' }} />
              <h3>Services & Fees</h3>
            </Link>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <Link to="https://app.managemyhealth.co.nz/authentication/login" target="_blank" className="button">
              <ScreenShareIcon sx={{ color: '#fff', fontSize: 45, marginBottom: '.5rem' }} />
              <h3>Manage My Health</h3>
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Buttons;
