import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { compose, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { has } from 'lodash';
import reducers from './reducers';

export function configureStore() {
  const initialState = {};
  const middlewares = [thunk];

  return createStore(
    reducers,
    initialState,
    compose(applyMiddleware(...middlewares)),
  );
}

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action = {}) {
    if (has(handlers, action.type)) {
      firebase.analytics().logEvent(action.type);
      return handlers[action.type](state, action);
    }
    return state;
  };
}
