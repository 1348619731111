import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableComponent from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import numeral from 'numeral';
import Grid from '@mui/material/Unstable_Grid2';
import { map, get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './selector';
import './style.scss';

const headers = [{
  id: 'child',
  label: 'Child',
  sublabel: '(below 14)',
}, {
  id: 'child2',
  label: 'Child',
  sublabel: '(14 - 17)',
}, {
  id: 'youth',
  label: 'Youth',
  sublabel: '(17 - 24)',
}, {
  id: 'adult',
  label: 'Adult',
  sublabel: '(24 - 64)',
}, {
  id: 'adult2',
  label: 'Adult',
  sublabel: '(above 65)',
}];

function Services({ pricing }) {
  const formatPrice = (price) => (
    <div className="price">
      <span>
        $
        {numeral(Math.floor(price)).format('0,0')}
      </span>
      <span className="decimal">{numeral(price).format('.00')}</span>
    </div>
  );
  const headerElements = map(headers, (headerItem) => (
    <TableCell key={headerItem.id}>
      {headerItem.label}
      <span className="sublabel">
        {headerItem.sublabel}
      </span>
    </TableCell>
  ));
  const standardDataElements = map(pricing.standardAppointmentPricing, (item) => {
    const popup = get(item, 'popup', '');
    const elements = map(headers, (headerItem) => (
      <TableCell key={`${headerItem.id}-${item.id}`}>
        {formatPrice(get(item, `pricing.${headerItem.id}`, 0))}
      </TableCell>
    ));
    return (
      <TableRow key={item.id}>
        <TableCell>
          <Tooltip title={popup}>
            <span className="tooltip">
              {item.label}
              {popup !== '' && (
              <InfoIcon sx={{
                marginLeft: '5px', marginTop: '5px', color: '#404040', fontSize: 12,
              }}
              />
              )}
            </span>
          </Tooltip>
        </TableCell>
        {elements}
      </TableRow>
    );
  });
  const renderTable = ({ title, data, note }) => {
    const dataElements = map(data, (item) => {
      const { popup, price } = item;
      const {
        amount, toAmount, prefix, suffix,
      } = price;

      return (
        <TableRow key={item.id}>
          <TableCell>
            <Tooltip title={popup}>
              <span className="tooltip">
                {item.label}
                {popup && (
                  <InfoIcon sx={{
                    marginLeft: '5px', marginTop: '5px', color: '#404040', fontSize: 12,
                  }}
                  />
                )}
              </span>
            </Tooltip>
          </TableCell>
          <TableCell>
            <div className="price-item">
              {prefix && (
              <span className="prefix">
                {prefix}
              </span>
              )}
              {formatPrice(amount)}
              {toAmount ? [
                (<span className="spacer"> - </span>),
                formatPrice(toAmount),
              ] : ''}
              {suffix && (
              <span className="suffix">
                {suffix}
              </span>
              )}
            </div>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <>
        <h3 className="table-section">{title}</h3>
        <TableContainer component={Paper} className="table-container">
          <TableComponent size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataElements}
            </TableBody>
          </TableComponent>
          {note && (<p>{note}</p>)}
        </TableContainer>
      </>
    );
  };

  return (
    <div className="component-services">
      <div className="content">
        <div className="table-header">
          <h2>Leamington Medial Centre Charges</h2>
        </div>
        <h3 className="table-section">Standard Appointment</h3>
        <TableContainer component={Paper} className="table-container">
          <TableComponent size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                {headerElements}
              </TableRow>
            </TableHead>
            <TableBody>
              {standardDataElements}
            </TableBody>
          </TableComponent>
          <p>
            All appointments are 15 minute duration, Extended consults incur additional charges.
          </p>
        </TableContainer>
        <Grid className="grid-container" container spacing={2}>
          <Grid xs={12} md={6}>
            {renderTable({ title: 'Nurse Procedures', data: pricing.nurseProcedurePricing })}
            {renderTable({ title: 'Administrative Fees', data: pricing.administrativeFeesPricing })}
          </Grid>
          <Grid xs={12} md={6}>
            {renderTable({ title: 'Prescriptions', data: pricing.prescriptionsPricing, note: 'Note, repeat scripts aim to be ready within 48 hours' })}
            {renderTable({ title: 'Other', data: pricing.otherPricing })}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

Services.propTypes = {
  pricing: PropTypes.shape({
    standardAppointmentPricing: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      pricing: PropTypes.shape({}).isRequired,
    })),
    nurseProcedurePricing: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        amountTo: PropTypes.number,
        prefix: PropTypes.string,
        suffix: PropTypes.string,
      }).isRequired,
    })),
    administrativeFeesPricing: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        amountTo: PropTypes.number,
        prefix: PropTypes.string,
        suffix: PropTypes.string,
      }).isRequired,
    })),
    prescriptionsPricing: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })),
    otherPricing: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })),
  }),
};

Services.defaultProps = {
  pricing: {
    standardAppointmentPricing: [],
    nurseProcedurePricing: [],
    prescriptionsPricing: [],
    administrativeFeesPricing: [],
    otherPricing: [],
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
