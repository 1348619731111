import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Address from '../address';
import Contact from '../contact';
import { mapStateToProps, mapDispatchToProps } from './selector';
import './style.scss';

function Footer({ sendSubscription, isSubscribed }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  function subscribe() {
    if (email !== '' && email !== undefined && email !== null && email.match('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$') !== null) {
      sendSubscription({ email });
    } else {
      setError('Please provide a valid email');
    }
  }

  return (
    <footer className="component-footer">
      <div className="top-section">
        <div className="content">
          <Grid container>
            <Grid xs={12} lg={6}>
              <h2>
                Email Communication
              </h2>
              <div className="spacer" />
              <p>
                Please provide use your email for Clinic updates and events
              </p>
              {isSubscribed ? (
                <h3>
                  Subscribed!
                </h3>
              ) : (
                <form>
                  <TextField
                    className="form-field"
                    variant="filled"
                    label="Email Address"
                    value={email}
                    error={error !== ''}
                    helperText={error}
                    onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                  />
                  <button type="button" className="button" onClick={() => subscribe()}>
                    Join Us
                  </button>
                </form>
              )}
            </Grid>
            <Grid
              xs={12}
              md={3}
              display={{
                xs: 'none', md: 'none', lg: 'block', xl: 'block',
              }}
            >
              <h2>
                Navigation
              </h2>
              <div className="spacer" />
              <nav>
                <Link to="/" className="link">
                  - Home
                </Link>
                <Link to="/about-us" className="link">
                  - About Us
                </Link>
                <Link to="/our-services" className="link">
                  - Services & Fees
                </Link>
                <Link to="https://www.mayoclinic.org/symptom-checker/select-symptom/itt-20009075" className="link" target="_blank">
                  - Health Resources
                </Link>
                <Link to="/enrol" className="link">
                  - Enrolment
                </Link>
                <Link to="https://app.managemyhealth.co.nz/authentication/login" className="link" target="_blank">
                  - Manage My Health
                </Link>
              </nav>
            </Grid>
            <Grid
              xs={12}
              md={3}
              display={{
                xs: 'none', md: 'none', lg: 'block', xl: 'block',
              }}
            >
              <h2>
                Get in touch
              </h2>
              <div className="spacer" />
              <Address primaryColor="#fff" color="#fff" />
              <Contact primaryColor="#fff" secondaryColor="#fff" color="#fff" />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="bottom-section">
        <div className="content">
          <span>{`Copyright © ${moment().format('YYYY')} Leamington Medical Centre. All Rights reserved.`}</span>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  sendSubscription: PropTypes.func.isRequired,
  isSubscribed: PropTypes.bool,
};

Footer.defaultProps = {
  isSubscribed: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
