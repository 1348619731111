import {
  collection, getFirestore, addDoc, getDocs,
  serverTimestamp, deleteDoc, doc, query, orderBy, writeBatch, where,
} from 'firebase/firestore';
import { map } from 'lodash';
import { formatDate } from '../subscriptions/actions';
import {
  SEND_FORM_WAITLIST_REQUEST,
  SEND_FORM_WAITLIST_RESPONSE,
  SEND_FORM_WAITLIST_FAILURE,
  GET_FORM_WAITLIST_REQUEST,
  GET_FORM_WAITLIST_RESPONSE,
  GET_FORM_WAITLIST_FAILURE,
} from './actions-definitions';

function sendWaitlistForm({
  name, otherNames, email, mobilePhone, homePhone, additionalDetails,
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_FORM_WAITLIST_REQUEST });
      const db = getFirestore();
      const waitlistsRef = collection(db, 'waitlists');

      addDoc(waitlistsRef, {
        name,
        otherNames,
        email,
        mobilePhone,
        homePhone,
        additionalDetails,
        status: 'WAITLISTED',
        createdAt: serverTimestamp(),
        lastChangedAt: serverTimestamp(),
        createdBy: 'SYSTEM',
        lastChangedBy: 'SYSTEM',
      })
        .then(() => {
          dispatch({ type: SEND_FORM_WAITLIST_RESPONSE });
        });
    } catch (err) {
      dispatch({ type: SEND_FORM_WAITLIST_FAILURE, err });
    }
  };
}

async function getWaitlistData({ orderByField, orderDirection }) {
  try {
    const db = getFirestore();
    const waitlistsRef = collection(db, 'waitlists');
    const q = query(waitlistsRef, orderBy(orderByField, orderDirection));

    const snapshot = await getDocs(q);
    return map(snapshot.docs, (waitlist) => {
      const data = waitlist.data();
      return {
        id: waitlist.id,
        ...data,
        createdAt: formatDate(data.createdAt),
        lastChangedAt: formatDate(data.lastChangedAt),
      };
    });
  } catch (err) {
    return [];
  }
}

function deleteWaitlist({
  id, orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORM_WAITLIST_REQUEST });
      const db = getFirestore();
      await deleteDoc(doc(db, 'waitlists', id));
      const waitlists = await getWaitlistData({ orderByField, orderDirection });
      dispatch({ type: GET_FORM_WAITLIST_RESPONSE, waitlists });
    } catch (err) {
      dispatch({ type: GET_FORM_WAITLIST_FAILURE, err });
    }
  };
}

function getWaitlists({
  orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORM_WAITLIST_REQUEST });
      const waitlists = await getWaitlistData({ orderByField, orderDirection });
      dispatch({ type: GET_FORM_WAITLIST_RESPONSE, waitlists });
    } catch (err) {
      dispatch({ type: GET_FORM_WAITLIST_FAILURE, err });
    }
  };
}

function deleteAllWaitlists({
  orderByField = 'createdAt', orderDirection = 'desc',
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORM_WAITLIST_REQUEST });
      const db = getFirestore();
      const batch = writeBatch(db);
      const waitlistsRef = collection(db, 'waitlists');
      const q = query(waitlistsRef, where('status', '==', 'WAITLISTED'));
      const snapshot = await getDocs(q);
      snapshot.docs.forEach((print) => {
        const ref = doc(db, 'waitlists', print.id);
        batch.delete(ref);
      });
      await batch.commit();
      const prints = await getWaitlistData({ orderByField, orderDirection });
      dispatch({ type: GET_FORM_WAITLIST_RESPONSE, prints });
    } catch (err) {
      dispatch({ type: GET_FORM_WAITLIST_FAILURE, err });
    }
  };
}

export {
  sendWaitlistForm,
  getWaitlistData,
  deleteWaitlist,
  getWaitlists,
  deleteAllWaitlists,
};
