import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import FormHelperText from '@mui/material/FormHelperText';
import {
  map, get, indexOf, reduce, each, set, omit, uniq, union,
} from 'lodash';
import { usePDF } from 'react-to-pdf';
import './style.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './selector';

const FORM_FIELDS = [
  {
    section: 'Name',
    subtext: 'required',
    fields: [
      {
        type: 'autocomplete',
        options: ['Mr.', 'Mrs.'],
        label: 'Title',
        value: 'title',
        mdSize: 1,
      },
      {
        label: 'Given Name',
        value: 'givenName',
        mdSize: 3,
        required: true,
      },
      {
        label: 'Other Given Name(s)',
        value: 'otherGivenNames',
        mdSize: 2,
      },
      {
        label: 'Family Name',
        value: 'familyName',
        mdSize: 3,
        required: true,
      },
      {
        label: 'Other Names',
        value: 'otherNames',
        mdSize: 2,
        subtext: 'eg. Maiden Name',
      },
    ],
  },
  {
    section: 'Birth Details',
    subtext: 'required',
    fields: [
      {
        type: 'date',
        label: 'Date of Birth',
        value: 'dateOfBirth',
        required: true,
        disableFuture: true,
        mdSize: 3,
      },
      {
        label: 'Place of Birth',
        value: 'placeOfBirth',
        required: true,
        mdSize: 3,
      },
      {
        type: 'autocomplete',
        options: ['Poland', 'New Zealand'],
        label: 'Country of Birth',
        value: 'countryOfBirth',
        required: true,
        mdSize: 3,
      },
      {
        type: 'spacer',
        mdSize: 2,
      },
    ],
  },
  {
    section: 'Gender',
    subtext: 'required',
    fields: [
      {
        type: 'autocomplete',
        options: ['Male', 'Female', 'Other'],
        label: 'Gender',
        value: 'gender',
        required: true,
        mdSize: 2,
      },
      {
        label: 'Gender diverse',
        value: 'otherGender',
        required: true,
        displayCondition: {
          field: 'gender',
          value: ['Other'],
        },
        mdSize: 2,
      },
      {
        type: 'spacer',
        displayCondition: {
          field: 'gender',
          value: ['Other'],
        },
        mdSize: 7,
      },
      {
        type: 'spacer',
        displayCondition: {
          field: 'gender',
          value: [null, 'Male', 'Female'],
        },
        mdSize: 9,
      },
    ],
  },
  {
    section: 'Occupation',
    fields: [
      {
        label: 'Occupation',
        value: 'occupation',
        subtext: 'eg. Student',
        mdSize: 4,
      },
      {
        type: 'spacer',
        mdSize: 7,
      },
    ],
  },
  {
    section: 'Residential Address',
    subtext: 'required',
    fields: [
      {
        label: 'House Number and Street Name',
        value: 'houseNumberAndStreetName',
        required: true,
        mdSize: 4,
      },
      {
        label: 'Suburb/Rural Location',
        value: 'suburbRuralLocation',
        required: true,
        mdSize: 3,
      },
      {
        label: 'Town/City',
        value: 'town',
        required: true,
        mdSize: 2,
      },
      {
        label: 'Post Code',
        value: 'postCode',
        required: true,
        mdSize: 2,
      },
    ],
  },
  {
    section: 'Postal Address',
    subtext: '(if different from above)',
    fields: [
      {
        label: 'House Number and Street Name',
        value: 'postalHouseNumberAndStreetName',
        mdSize: 4,
      },
      {
        label: 'Suburb/Rural Location',
        value: 'postalSuburbRuralLocation',
        mdSize: 3,
      },
      {
        label: 'Town/City',
        value: 'postalTown',
        mdSize: 2,
      },
      {
        label: 'Post Code',
        value: 'postalPostCode',
        mdSize: 2,
      },
    ],
  },
  {
    section: 'Contact Details',
    subtext: 'required',
    fields: [
      {
        label: 'Mobile Phone',
        value: 'mobilePhone',
        required: true,
        mdSize: 3,
      },
      {
        label: 'Home Phone',
        value: 'homePhone',
        required: true,
        mdSize: 3,
      },
      {
        label: 'Email Address',
        value: 'emailAddress',
        required: true,
        mdSize: 4,
      },
      {
        type: 'spacer',
        mdSize: 1,
      },
    ],
  },
  {
    section: 'Emergency Contact',
    subtext: 'required',
    fields: [
      {
        label: 'Name',
        value: 'emergencyName',
        required: true,
        mdSize: 4,
      },
      {
        label: 'Relationship',
        value: 'emergencyRelationship',
        required: true,
        mdSize: 3,
      },
      {
        label: 'Phone',
        value: 'emergencyPhone',
        subtext: 'Mobile or Home',
        required: true,
        mdSize: 3,
      },
      {
        type: 'spacer',
        mdSize: 1,
      },
    ],
  },
  {
    section: 'Ethnicity Details',
    fields: [
      {
        type: 'autocomplete',
        options: ['New Zealand European', 'Maori', 'Samoan', 'Cook Island Maori', 'Tongan', 'Niuean', 'Chinese', 'Indian', 'Other'],
        label: 'Ethnicity',
        value: 'ethnicity',
        mdSize: 3,
      },
      {
        label: 'Other',
        value: 'otherEthnicity',
        subtext: 'eg. Duch, Japanese, Tokelauan',
        displayCondition: {
          field: 'ethnicity',
          value: ['Other'],
        },
        mdSize: 4,
      },
      {
        label: 'Iwi',
        value: 'iwi',
        subtext: 'if applicable',
        displayCondition: {
          field: 'ethnicity',
          value: ['Maori', 'Cook Island Maori'],
        },
        mdSize: 2,
      },
      {
        label: 'Hapu',
        value: 'hapu',
        subtext: 'if applicable',
        displayCondition: {
          field: 'ethnicity',
          value: ['Maori', 'Cook Island Maori'],
        },
        mdSize: 2,
      },
      {
        type: 'spacer',
        mdSize: 8,
        displayCondition: {
          field: 'ethnicity',
          value: [null, 'New Zealand European', 'Samoan', 'Tongan', 'Niuean', 'Chinese', 'Indian'],
        },
      },
      {
        type: 'spacer',
        mdSize: 4,
        displayCondition: {
          field: 'ethnicity',
          value: ['Other'],
        },
      },
      {
        type: 'spacer',
        mdSize: 4,
        displayCondition: {
          field: 'ethnicity',
          value: ['Maori', 'Cook Island Maori'],
        },
      },
    ],
  },
  {
    section: 'Other Details',
    fields: [
      {
        type: 'autocomplete',
        options: ['Yes', 'No'],
        label: 'Community Services Card',
        value: 'communityServicesCard',
        mdSize: 3,
      },
      {
        type: 'date',
        label: 'Card Expiry',
        value: 'communityServicesCardExpiry',
        displayCondition: {
          field: 'communityServicesCard',
          value: ['Yes'],
        },
        mdSize: 3,
      },
      {
        label: 'Card Number',
        value: 'communityServicesCardNumber',
        displayCondition: {
          field: 'communityServicesCard',
          value: ['Yes'],
        },
        mdSize: 4,
      },
      {
        type: 'spacer',
        mdSize: 1,
        displayCondition: {
          field: 'communityServicesCard',
          value: ['Yes'],
        },
      },
      {
        type: 'spacer',
        mdSize: 8,
        displayCondition: {
          field: 'communityServicesCard',
          value: [null, 'No'],
        },
      },
      {
        type: 'spacer',
        mdSize: 1,
      },
      {
        type: 'autocomplete',
        options: ['Yes', 'No'],
        label: 'High User Health Card',
        value: 'highUserHealthCard',
        mdSize: 3,
      },
      {
        type: 'date',
        label: 'Card Expiry',
        value: 'highUserHealthCardExpiry',
        displayCondition: {
          field: 'highUserHealthCard',
          value: ['Yes'],
        },
        mdSize: 3,
      },
      {
        label: 'Card Number',
        value: 'highUserHealthCardNumber',
        displayCondition: {
          field: 'highUserHealthCard',
          value: ['Yes'],
        },
        mdSize: 4,
      },
      {
        type: 'spacer',
        mdSize: 1,
        displayCondition: {
          field: 'highUserHealthCard',
          value: ['Yes'],
        },
      },
      {
        type: 'spacer',
        mdSize: 8,
        displayCondition: {
          field: 'highUserHealthCard',
          value: [null, 'No'],
        },
      },
      {
        type: 'spacer',
        mdSize: 1,
      },
      {
        type: 'autocomplete',
        subtext: 'if over 15',
        options: ['Never smoked', 'Current smoker', 'Ex-smoker'],
        label: 'Smoking status',
        value: 'smokingStatus',
        mdSize: 3,
      },
      {
        type: 'autocomplete',
        options: ['More than 15 months', 'Less than 12 months'],
        label: 'Not smoking for',
        value: 'notSmoking',
        mdSize: 3,
        displayCondition: {
          field: 'smokingStatus',
          value: ['Ex-smoker'],
        },
      },
      {
        type: 'autocomplete',
        options: ['Yes', 'No'],
        label: 'Would you like support to quit smoking?',
        subtext: 'If you are a current smoker or have recently quit, we would like to help you stop, to improve your health. Would you like help to stop/stay an ex-smoker?',
        value: 'notSmokingSupport',
        mdSize: 3,
        displayCondition: {
          field: 'smokingStatus',
          value: ['Current smoker', 'Ex-smoker'],
        },
      },
    ],
  },
];
const ELIGIBILITY_FIELDS = [
  {
    id: 'entitled',
    hideId: true,
    label: 'I am entitled to enrol because I am residing permanently in New Zealand.',
    sublabel: 'The definition of residing permanently in NZ is that you intend to be resident in New Zealand for at least 183 days in the next 12 months',
  },
  {
    id: 'A',
    title: 'AND I am eligible to enrol because:',
    prefix: 'A',
    label: 'I am a New Zealand citizen',
    sublabel: 'If yes, tick box and proceed to “I confirm that, if requested, I can provide proof of my eligibility below”',
    disabled: ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
  },
  {
    id: 'B',
    title: 'If you are NOT a New Zealand citizen please tick which eligibility criteria applies to you (B-J) below:',
    prefix: 'B',
    label: 'I hold a resident visa or a permanent resident visa (or a residence permit if issued before December 2010',
    disabled: ['A', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
  },
  {
    id: 'C',
    prefix: 'C',
    label: 'I am an Australian citizen or Australian permanent resident AND able to show I have been in New Zealand or intend to stay in New Zealand for at least 2 consecutive years',
    disabled: ['A', 'B', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
  },
  {
    id: 'D',
    prefix: 'D',
    label: 'I have a current work visa/permit and can show that I am legally able to be in New Zealand for at least 2 years (previous visa/Permits included)',
    disabled: ['A', 'B', 'C', 'E', 'F', 'G', 'H', 'I', 'J'],
  },
  {
    id: 'E',
    prefix: 'E',
    label: 'I am an interim visa holder who was eligible immediately before my interim visa started',
    disabled: ['A', 'B', 'C', 'D', 'F', 'G', 'H', 'I', 'J'],
  },
  {
    id: 'F',
    prefix: 'F',
    label: 'I am a refugee or protected person OR in the process of applying for, or appealing refugee or protection status, OR a victim Or suspected victim of people trafficking',
    disabled: ['A', 'B', 'C', 'D', 'E', 'G', 'H', 'I', 'J'],
  },
  {
    id: 'G',
    prefix: 'G',
    label: 'I am under 18 years and in the care and control of a parent/legal guardian/adopting parent who meets one criterion in clauses a-f above OR in the control of the Chief Executive of the Ministry of Social Development',
    disabled: ['A', 'B', 'C', 'D', 'E', 'F', 'H', 'I', 'J'],
  },
  {
    id: 'H',
    prefix: 'H',
    label: 'I am a NZ Aid Programme student studying in NZ and receiving Official Development Assistance funding (or their partner or Child under 18 years old)',
    disabled: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'I', 'J'],
  },
  {
    id: 'I',
    prefix: 'I',
    label: 'I am participating in the Ministry of Education Foreign Language Teaching Assistantship scheme',
    disabled: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J'],
  },
  {
    id: 'J',
    prefix: 'J',
    label: 'I am a Commonwealth Scholarship holder studying in NZ and receiving funding from a New Zealand university under the Commonwealth Scholarship and Fellowship Fund',
    disabled: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
  },
  {
    id: 'confirmation',
    hideId: true,
    label: 'I Confirm that, if requested I can provide proof of my eligibility',
  },
];

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 20,
  height: 20,
  boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath"
        + " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 "
        + "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

function EnrolForm({ sendFormPrint }) {
  const [pdfGenerated, setPDFGenerated] = useState(true);
  const [form, setFormField] = useState({
    gender: null,
    ethnicity: null,
    communityServicesCard: null,
    highUserHealthCard: null,
  });
  const [eligibilities, setEligibilities] = useState({});
  const [validation, setValidation] = useState({});
  const { toPDF, targetRef } = usePDF({ filename: `Leamington Family Clinic - Registration Form - ${form.givenName} ${form.familyName}.pdf` });

  function isFieldVisible(field) {
    if (field.displayCondition) {
      if (indexOf(field.displayCondition.value, get(form, field.displayCondition.field)) === -1) {
        return false;
      }
    }
    return true;
  }

  function validateField(field, value) {
    const isVisible = isFieldVisible(field);
    if (field.required && isVisible && (value === '' || value === undefined || value === null)) {
      setValidation({
        ...validation,
        [field.value]: `${field.label} is required`,
      });
    } else if (get(validation, field.value)) {
      setValidation({
        ...omit(validation, [field.value]),
      });
    }
  }

  function validateForm() {
    const newValidation = reduce(FORM_FIELDS, (memo, section) => {
      each(section.fields, (field) => {
        const value = get(form, field.value, null);
        const isVisible = isFieldVisible(field);
        if (field.required && isVisible && (value === '' || value === undefined || value === null)) {
          set(memo, field.value, `${field.label} is required`);
        }
      });
      return memo;
    }, {});
    setValidation(newValidation);
    return Object.keys(newValidation).length === 0;
  }

  function generatePDF() {
    const isValid = validateForm();
    if (isValid) {
      sendFormPrint({
        name: `${form.familyName}, ${form.givenName}`, email: form.emailAddress, mobilePhone: form.mobilePhone, homePhone: form.homePhone,
      });
      setPDFGenerated(true);
      setTimeout(() => {
        toPDF();
        setTimeout(() => {
          setPDFGenerated(false);
        }, 500);
      }, 500);
    }
  }

  function onChange(field, value) {
    setFormField({
      ...form,
      [field]: value,
    });
  }

  const sections = map(FORM_FIELDS, (section, index) => {
    const fields = map(section.fields, (field, fieldIndex) => {
      let fieldElement;
      const value = get(form, field.value);
      const error = get(validation, field.value, '');
      const hasError = error !== '';
      const isVisible = isFieldVisible(field);
      if (!isVisible) {
        return null;
      }
      if (field.type === 'autocomplete') {
        fieldElement = (
          <Autocomplete
            value={value}
            required={field.required || false}
            options={field.options}
            onChange={(e, newValue) => onChange(field.value, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                required={field.required || false}
                label={field.label}
                error={hasError}
                helperText={error}
                onBlur={() => validateField(field, value)}
              />
            )}
          />
        );
      } else if (field.type === 'date') {
        fieldElement = (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              className="form-field"
              label={field.label}
              value={value}
              required={field.required || false}
              onChange={(date) => onChange(field.value, date.format('DD/MM/YYYY'))}
              disableFuture={field.disableFuture || false}
              format="DD/MM/YYYY"
              error={hasError}
              helperText={error}
              onBlur={() => validateField(field, value)}
            />
          </LocalizationProvider>
        );
      } else if (field.type === 'spacer') {
        return (
          <Grid key={`field-${index}-${fieldIndex}`} xs={pdfGenerated ? field.mdSize : 12} md={field.mdSize} />
        );
      } else {
        fieldElement = (
          <TextField
            className="form-field"
            label={field.label}
            value={value}
            required={field.required || false}
            onChange={(e) => onChange(field.value, e.target.value)}
            error={hasError}
            helperText={error}
            onBlur={(e) => validateField(field, e.target.value)}
          />
        );
      }

      return (
        <Grid key={`field-${index}-${fieldIndex}`} xs={pdfGenerated ? field.mdSize : 12} md={field.mdSize}>
          {fieldElement}
          {field.subtext && (<FormHelperText>{field.subtext}</FormHelperText>)}
        </Grid>
      );
    });

    return [
      <Grid key={`section-${index}`} xs={pdfGenerated ? 1 : 12} md={1}>
        <span className="label">{section.section}</span>
        {section.subtext && (<span className="subext">{section.subtext}</span>)}
      </Grid>,
      fields,
    ];
  });
  const disabledFields = uniq(reduce(ELIGIBILITY_FIELDS, (memo, field) => {
    if (get(eligibilities, field.id, false)) {
      return union(memo, field.disabled || []);
    }
    return memo;
  }, []));
  const eligibilityFields = map(ELIGIBILITY_FIELDS, (field) => (
    <div key={field.id} className="entitlement-field column">
      {field.title && (<p>{field.title}</p>)}
      <div>
        {!field.hideId && (<div className="box id">{field.id}</div>)}
        <div className="column box requirement">
          <span>{field.label}</span>
          <span>{field.sublabel}</span>
        </div>
        <div className="box">
          <Checkbox
            icon={<BpIcon />}
            checkedIcon={<BpCheckedIcon />}
            backgroundColor="#fff"
            disabled={indexOf(disabledFields, field.id) !== -1}
            checked={get(eligibilities, field.id, false)}
            onChange={(e) => {
              setEligibilities({ ...eligibilities, [field.id]: e.target.checked });
            }}
          />
        </div>
      </div>
    </div>
  ));

  return (
    <div className={`component-enrol-form ${pdfGenerated && 'pdf-generated'}`} ref={targetRef}>
      <div className="content">
        <h2 id="enrollment-form">
          <span>Online</span>
          <span className="highlight-color">Enrolment Form</span>
        </h2>
        <p>
          Below is an online enrolment form, once completed you can either
          email it to the clinic directly (from this page) or print it out
          yourself and return it back to the clinic to be finalized.
        </p>
        <form autoComplete="off">
          <div className="form-section">
            Patient Information
          </div>
          <Grid className="grid-container" container spacing={2}>
            {sections}
          </Grid>
          <div className="form-section">
            My declaration of entitlement and eligibility
          </div>
          {eligibilityFields}
          <div className="form-section">
            My agreement to the enrolment process
            (NB, Parent, or Caregiver to sign if you are under 16 years)
          </div>
          <p>
            <b>I intend to use this practice</b>
            {' '}
            as my regular and
            ongoing provider of general practice / GP / health care services.
            <br />
            <br />
            <b>I understand</b>
            {' '}
            that by enroling with this practice
            I will be included in the enrolment population of
            this practice’s Primary Health Organisation (PHO) National
            Hauora Coalition and my name address and other
            identification details will be included on the
            Practice, PHO and National Enrolment Service Registers.
            <br />
            <br />
            <b>I understand</b>
            {' '}
            that if I visit another health care
            provider where I am not enroled I may be charged a higher fee.
            <br />
            <br />
            <b>I have been given information</b>
            {' '}
            about the benefits
            and implications of enrolment and the services
            this practice and PHO provides along with the PHO’s name and
            contact details.
            <br />
            <br />
            <b>I have read and I understand</b>
            {' '}
            the Use of Health
            Information Statement. The information I have
            provided on the Enrolment Form will be used to determine
            eligibility to  receive publicly-funded services.
            Information may be compared with other government
            agencies, but only when permitted under the Privacy Act.
            <br />
            <br />
            <b>I understand</b>
            {' '}
            that the Practice participates in a
            national survey about people’s health care experience
            and how their overall care is managed. Taking apart is voluntary
            and all responses will be anonymous. I can decline
            the survey or opt out of the survey by informing
            the Practice. The survey provides important information that is
            used to improve health services.
            <br />
            <br />
            <b>I agree</b>
            {' '}
            to inform the practice of any changes in
            my contact details and entitlement and/or eligibility to be enroled.
          </p>
        </form>
      </div>
      <div className="submit">
        <button type="button" className="button" onClick={() => generatePDF()}>
          Print Form & Sign
        </button>
        <p>
          When selected, you will be prompted to print the
          completed form. Once completed, and signed, either
          bring it by the clinic or email us the completed form
          to
          {' '}
          <a href="mailto:admin@leamingtonmc.co.nz">admin@leamingtonmc.co.nz</a>
          .
          <br />
          <br />
          Be sure to include your photo ID, proof of
          eligibility to enrol (NZ passport or NZ birth
          certificate or valid visa) and proof of address.
          We’ll take care of the rest!
        </p>
      </div>
    </div>
  );
}

EnrolForm.propTypes = {
  sendFormPrint: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrolForm);
